import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgScanTicket from "../images/shuttleid-student-scan.jpg"
import imgSnowDay from "../images/snow-day.jpg"
import imgFlexiblePayments from "../images/flexible-payment-terms.jpg"
import imgStudentTicketPhone from "../images/student-with-ticket-on-phone.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="7 ways to keep parents happy on your home-to-school services" />
        <Nav activeTab="blog" />
        <div>
            <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">7 ways to keep parents happy on your home-to-school services</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">7 ways to keep parents happy on your home-to-school services</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>If you’re concerned about competition on your school routes from other transport operators and even parents car sharing, it may be time to examine what extra value you can add to your school services.</p>
                    <p>Providing an enhanced service that offers convenience, flexibility and safety is what parents want in today’s climate. Here are seven ways you can make parents happy and help stay ahead of the competition by using a system like ShuttleID:</p>
                  </div>
                  <div className='article-inner'>

                    <h2>1. Enhanced safeguarding</h2>
                    <p><img src={imgScanTicket} alt="" /></p>
                    <p>Imagine the scenario; little Johnny should have been home for dinner half an hour ago - but he isn’t answering the phone and his parents are becoming increasingly worried. They look to you as a transport operator for answers. Did little Johnny get on the bus this afternoon? Where would you even begin? How long would it take you to come to a satisfactory conclusion?</p>
                    <p>With ShuttleID, operators have access to scan logs and know exactly who uses their school services and when. So if the dreaded call ever came in, you’d be prepared and able to give an answer in seconds, which could make a huge difference in a critical situation.</p>
                    <p>Giving parents the assurance you have safeguarding tools available could make all the difference in the decision for parents to place trust in you and your services.</p>

                    <h2>2. Buy tickets 24/7</h2>
                    <p>Parents are busy people. Having to call up to buy a bus pass within office hours not only takes away your valuable time but it is an inconvenience for parents. With ShuttleID, parents can purchase tickets online 24/7, in the evening or weekend, at a time convenient for them.</p>
                    <img src={imgFlexiblePayments} alt="" />

                    <h2>3. Flexible payment terms</h2>
                    <p>Some operators only consider offering an annual or term payment in one lump sum payment, as it keeps their manual processes just about manageable. But have you ever wondered if your payment terms are pricing potential customers out? By offering more flexibility in your payment terms, you could see an upswing in patronage on your school services.</p>
                    <p>In a manual system, it would be very difficult to manage monthly payments. With a system like ShuttleID that automates payments and ticket issuing, it’s now possible. In fact, our most popular ticket option offered by operators, and chosen by parents, is the annual pass with monthly payment terms. This helps get the commitment from the parents that they are signing up for the year, but spreads the cost so it’s financially accessible.</p>
                    <p>The system ensures admin is minimal, and you receive an instant notification of any individual failed payments, with an option to void the ticket. This means you still retain control, even with the increased flexibility offered to parents.</p>

                    <h2>4. Travel flexibility to rival the car share</h2>
                    <p>For school services that are not already oversubscribed, your priority will be to try and ensure empty seats are filled. ShuttleID gives operators the ability to list different ticket types of varying flexibility, helping ensure revenue is maximised.</p>
                    <p>As some parents plan to car share or use different transportation for certain days of the week, depending on afterschool clubs etc, having this flexibility available to you can be really effective and can help encourage parents to ditch the car..</p>

                    <h2>5. Instant ticket delivery</h2>
                    {/* <img src={imgNeedTicket} alt="" /> */}
                    <p style={{ background: '#f9f9c2', padding: 15 }}><em style={{ fontWeight: 'bold' }}>"This was the easiest ever and I’m no good with IT, fantastic system, been with you for years posting to you in snail mail. Well done for a great system."</em> - Parent feedback</p>
                    <p>Parents want to obtain a bus pass in the quickest way possible. Having to complete lengthy application forms, post photographs and call to make card payments over the phone will lead to frustration and delays.</p>
                    <p>For competitive routes, having the capability to list, sell, and deliver your bus pass before your competitor may be the deciding factor for parents that crave both convenience and also assurance travel is sorted for their child.</p>
                    <p>If parents have left it late to buy their ticket they may be worrying that they won’t receive their printed pass ready for the child’s first day. Delivering an electronic ticket instantly removes any postal delays, which means they can leave it as late as minutes before travel.</p>



                    <h2>6. First class communication</h2>
                    <img src={imgSnowDay} alt="" />
                    <p>Parents don’t necessarily want to know today that the school bus is running on time, this should be a given. What they do need to know however is when there is a divergence from the schedule, which is hopefully not as common.</p>
                    <p>Without good and proactive communication, this leads to panicked parents flooding the office calls and email, at a time your team needs this least. Using facebook, or other social media platforms to notify parents if a bus is late can cause a lot of users to miss critical announcements.</p>
                    <p>ShuttleID gives operators a powerful SMS based service alert system. All parents in any given service can receive a text message letting them know a service is late or cancelled, whether this be due to traffic or bad weather. Your critical message is sent out instantly to parents, achieving high visibility and reassuring customers the situation is in hand.</p>

                    <h2>7. No more pass replacement fees</h2>
                    <img src={imgStudentTicketPhone} alt="" />
                    <p>Little Johnny keeps losing his pass, and each time this happens, it needs a busy parent to fill out forms and contact the office during working hours. All while adding uncertainty around whether the driver will let the child on the bus. To add insult to injury, the replacement pass comes with an admin fee, which requires the parent to hand over even more money begrudgingly.</p>
                    <p>With ShuttleID, tickets can be accessed 24/7, putting an end to replacement pass fees. The system not only offers the ability to use a ticket on a phone, which is much less likely to go missing, but with the print-at-home option this means parents also have a backup.</p>
                    <p style={{ background: '#f9f9c2', padding: 15 }}><em style={{ fontWeight: 'bold' }}>"Really easy! And I like the fact that we have an electronic ticket. Took about 3 minutes to do."</em> - Parent feedback</p>


                    {/* <h2>"The best thing we've done in years"</h2>
                    <p>If this article has struck a chord with you, you might be interested to learn a bit more about ShuttleID, a simple mobile ticketing system for home-to-school travel.</p>
                    <p>The system typically <strong>saves transport operators hundreds of hours in admin</strong> every year and the feedback we hear most from customers is the regret they didn't do something like this sooner. Here's how we can help:</p>
                    <ul style={{ width: 'auto' }}>
                      <li><strong>No more postal application forms.</strong> On our <Link to="/complete-package/">Complete Package</Link>, parents complete the passenger details securely online and receive their pass instantly.</li>
                      <li><strong>Photo uploads.</strong> In ShuttleID, parents upload a passenger photo securely for you to have access to in the back office in the event of an incident. No more cropping, resizing or scanning for you.</li>
                      <li><strong>Sell tickets online.</strong> Take payment (card and direct debit) automatically online using our Complete package. Just list the tickets for sale and parents snap them up when it's convenient for them.</li>
                      <li><strong>Automate scheduled payments.</strong> Our system gives you flexibility to offer instalments via direct debit. This way offers major differences to standing orders, giving you total control and a way to void passes if the payments ever stop.</li>
                      <li><strong>Go cashless.</strong> Going cashless takes the pressure of parents to find the correct change every week but also saves you all the headaches that come with having to handle cash. Read our <Link to="/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/">case study of how we helped Applegates Coaches go cashless</Link>.</li>
                      <li><strong>Mobile ticketing.</strong> Our system gives customers what they want - their bus pass instantly and available to use on their phone.</li>
                      <li><strong>Scrap replacement passes.</strong> With digital passes available to customers 24/7, replacement passes are no longer a concept, and no longer a job you have to deal with!</li>
                      <li><strong>Turn registers digital.</strong> With each pass scanned at boarding, you automatically get a register of passengers for every journey which has proved invaluable during the pandemic but always a key feature for safeguarding purposes.</li>
                      <li><strong>Fast and direct communication.</strong> Our SMS service alerts allow you to select a service and blast out a message in seconds, keeping parents in the know and preventing the flood of phone calls into the office.</li>
                      <li><strong>Streamline renewals.</strong> Our renewal system significantly reduces up to 80% of the admin involved with taking payment and issuing passes, whilst also making it super convenient for parents.</li>
                    </ul>*/}

                    {/* <h2>"The best thing we've done in years"</h2>
                    <p>If you want to stay ahead of the competition and provide unrivalled value to parents, <Link to="/#contact">get in touch</Link> today. It can take as little as 24 hours to get set up and it’s available to operators of all sizes.</p> */}

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, a cost effective cashless ticketing system designed to make managing home to school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home to school first hand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage